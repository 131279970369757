// ==============================================================================
// Slide up and down animations

@keyframes slide-up {
  0% {
    animation-timing-function: ease-in;
    transform: translateY(40px);
    opacity: 0;
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0px);
  }
}

.slide-up {
  animation: slide-up 0.15s ease-in-out 0s 1 normal forwards;
  animation-delay: 0s; // in case we want to add a delay to the opening animation
  transform: translateY(40px);
  visibility: visible;
  opacity: 0;
}

@keyframes slide-down {
  0% {
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  100% {
    animation-timing-function: ease-in-out;
    transform: translateY(100%);
    opacity: 0;
  }
}

.slide-down {
  animation: slide-down 0.2s ease-in-out 0s 1 normal forwards;
}

// ==============================================================================
// Fade in and out animations

@keyframes fade-in {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 0.25s ease-in-out 0s 1 normal forwards;
  animation-delay: 0s; // in case we want to add a delay to the opening animation
  visibility: visible;
  opacity: 0;
}

@keyframes fade-out {
  0% {
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  100% {
    animation-timing-function: ease-in-out;
    opacity: 0;
    visibility: hidden;
  }
}

.fade-out {
  animation: fade-out 0.2s ease-in-out 0s 1 normal forwards;
}

// ==============================================================================

// .fade-in-slow {
//   animation: fade-in 0.35s ease-in-out 0s 1 normal forwards;
//   visibility: visible;
// }

// .fade-out-slow {
//   animation: fade-out 0.35s ease-in-out 0s 1 normal forwards;
// }

// ==============================================================================
// Scale in and out animations

@keyframes scale-in {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: scale(1);
  }
}

.scale-in {
  animation: scale-in 0.25s ease-in-out 0s 1 normal forwards;
  animation-delay: 0s; // in case we want to add a delay to the opening animation
  visibility: visible;
  opacity: 0;
  transform: scale(0);
}

@keyframes scale-out {
  0% {
    animation-timing-function: ease-in-out;
    opacity: 1;
    transform: scale(1);
  }

  100% {
    animation-timing-function: ease-in-out;
    opacity: 0;
    transform: scale(0.5);
    visibility: hidden;
  }
}

.scale-out {
  animation: scale-out 0.2s ease-in-out 0s 1 normal forwards;
}

// ==============================================================================
// Pulse animation
.pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

// ==============================================================================
// Blink - used on play button and finger tip(tap-to-earn)

@keyframes blink {
  0% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
  15% {
    opacity: 0.9;
    transform: translate(0, 0) scale(0.94);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
    transition-timing-function: cubic-bezier(0, 0, 0.6, 1);
  }
}

// ==============================================================================
// Hand move animation - in case we need it on FTUEs

.hand-move {
  animation: hand-move 1s infinite;
}

@keyframes hand-move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

// ==============================================================================
