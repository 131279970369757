// ==========================================
// General button behaviour

.btn {
  pointer-events: auto;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  transition: transform 0.1s ease-in-out;
  &:active {
    transform: scale(0.95);
  }

  &.disabled,
  &[disabled],
  &:disabled {
    opacity: 0.6;
    cursor: initial;
    pointer-events: none;
  }
}

// ==========================================
// Black and White buttons

.btn-normal,
.btn-inverse {
  position: relative;

  // inverse by default
  background-color: #111;
  color: #fff;

  border: unset;
  border-radius: 40px;

  font-family: PPPangramSansRounded-Bold;
  font-size: 16px;
  user-select: none;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 52px; // 64px;
  margin: 0;
}

.btn-normal {
  background-color: #fff;
  color: #111;
  border: 1px solid #111;
  width: 100%;

  &.share {
    width: 80px;
    img {
      width: 40%;
    }
  }
}

.btn-inverse {
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
}

// ==========================================
// Footer buttons

.footer-buttons {
  width: 100%;

  display: flex;
  flex-direction: row-reverse;
  gap: 8px;

  &.vertical {
    flex-direction: column;
    gap: 12px;
  }
}

// ==========================================